import React from 'react'
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

const TextContainer = ({ props }) => {

  return (
    <BackgroundImage fluid={props.fluid} className={`grid grid-flow-row justify-items-center items-start text-xs xl:text-base h-full w-screen text-black`}>
        {props.cards.map((card) => {
          return (
            <div className={`${card.className} grid grid-cols-1 w-1/2 mt-tw mb-tw`}>

                <p className={`${card.headerClass} font-bold`}>{card.header}</p>

                <p className={`${card.bodyClass}`}>{card.body}</p>

            </div>
          )
        })}
      </BackgroundImage>
  )
}

export default TextContainer 
